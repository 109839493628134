
import {
  Component,
  InjectReactive,
  Mixins,
  Ref,
  Vue,
  Watch,
} from "vue-property-decorator";
import gql from "graphql-tag";
import { DepositPaymentSystemEntity } from "@/entities/deposit-payment-system.entity";
import GradientBtn from "@/components/base/GradientBtn.vue";
import * as uniq from "lodash/uniq";
import { Config } from "vuescroll";
import { USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";
import { AuthUserFragment } from "@/graphql/fragments";
import { IPromoCode } from "@/interfaces/promo-code.interface";
import PromoCodeMixin from "@/mixins/promo-code.mixin";

const defaultPopularGiveAmounts = [10, 50, 100, 250, 500, 1000, 1500, 3000];

@Component({
  components: {
    GradientBtn,
  },
  apollo: {
    getAmount: {
      query: gql`
        query (
          $giveAmount: Float!
          $paymentSystem: UserAvailableDepositPaymentSystemEnum!
        ) {
          calculateDepositGetAmount(
            giveAmount: $giveAmount
            paymentSystem: $paymentSystem
          )
        }
      `,
      skip() {
        return true;
      },
      variables() {
        return {
          paymentSystem: this.selectedPaymentSystem._id,
          giveAmount: this.giveAmount || 0,
        };
      },
      update(data) {
        return typeof data.calculateDepositGetAmount === "number"
          ? data.calculateDepositGetAmount || null
          : null;
      },
    },
    giveAmount: {
      query: gql`
        query (
          $getAmount: Float!
          $paymentSystem: UserAvailableDepositPaymentSystemEnum!
        ) {
          calculateDepositGiveAmount(
            getAmount: $getAmount
            paymentSystem: $paymentSystem
          )
        }
      `,
      skip() {
        return true;
      },
      variables() {
        return {
          paymentSystem: this.selectedPaymentSystem._id,
          getAmount: this.getAmount || 0,
        };
      },
      update(data) {
        return typeof data.calculateDepositGiveAmount === "number"
          ? data.calculateDepositGiveAmount || null
          : null;
      },
    },
    paymentSystems: {
      update(data) {
        const paymentSystems = data.depositPaymentSystems
          ? data.depositPaymentSystems.map(
              (value) => new DepositPaymentSystemEntity(value)
            )
          : null;

        if (paymentSystems) {
          this.selectedPaymentSystem = paymentSystems[0];
        }

        return paymentSystems;
      },
      query: gql`
        query {
          depositPaymentSystems {
            _id
            commission
            minGiveAmount
          }
        }
      `,
    },

    popularGiveAmounts: {
      skip() {
        return !this.selectedPaymentSystem;
      },
      update(data) {
        let giveAmounts = data.popularDepositGiveAmounts
          ? data.popularDepositGiveAmounts
          : null;

        if (giveAmounts && giveAmounts.length < 10) {
          giveAmounts = uniq(giveAmounts.concat(defaultPopularGiveAmounts));
        }

        return giveAmounts;
      },
      query: gql`
        query ($paymentSystem: UserAvailableDepositPaymentSystemEnum!) {
          popularDepositGiveAmounts(paymentSystem: $paymentSystem)
        }
      `,
      variables() {
        return {
          paymentSystem: this.selectedPaymentSystem?._id,
        };
      },
    },
  },
})
export default class DepositContent extends Mixins(PromoCodeMixin) {
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  paymentSystems: DepositPaymentSystemEntity[] | null = null;
  popularGiveAmounts: number[] = defaultPopularGiveAmounts;
  selectedPaymentSystem: DepositPaymentSystemEntity | null = null;
  promoCode!: string;
  giveAmount: number | null = null;
  getAmount: number | null = null;
  email = "";

  loading = false;
  private scrollOptions: Config = {
    rail: {
      keepShow: false,
    },
    bar: {
      disable: true,
    },
    vuescroll: {
      mode: "slide",
      zooming: false,
    },
    scrollPanel: {
      scrollingX: true,
      scrollingY: false,
    },
  };
  @Ref("form") form: any;
  @Ref("giveAmountInput") giveAmountInput: any;

  @Watch("selectedPaymentSystem", {})
  async selectedPaymentSystemWatcher() {
    this.form.validate();
    this.calculateGiveAmount();
  }

  isEmail(val: string) {
    return /^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(
      val
    );
  }

  created() {
    if (this.$route.query.getAmount) {
      this.getAmount = Number(this.$route.query.getAmount);
    }

    if (this.$route.query.promoCode) {
      this.promoCode = this.$route.query.promoCode as string;
    }

    this.email = this.user?.email || "";
  }

  setGiveAmount(value: number) {
    this.giveAmount = value;
    this.calculateGetAmount();
  }

  calculateGetAmount() {
    this.$apollo.queries.getAmount.start();
  }

  calculateGiveAmount() {
    this.$apollo.queries.giveAmount.start();
  }

  async createDeposit() {
    this.loading = true;

    if (this.selectedPaymentSystem?.email && this.user?.email !== this.email) {
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($input: UpdateUserInput!) {
            updateUser(input: $input) {
              ...AuthUser
            }
          }
          ${AuthUserFragment}
        `,
        variables: {
          input: {
            email: this.email,
          },
        },
      });
    }

    let deposit = await this.$apollo
      .mutate({
        mutation: gql`
          mutation ($input: CreateDepositInput!) {
            createDeposit(input: $input) {
              _id
            }
          }
        `,
        variables: {
          input: {
            paymentSystem: this.selectedPaymentSystem!._id,
            giveAmount: this.giveAmount || undefined,
            ...(this.promoCode ? { promoCode: this.promoCode } : {}),
          },
        },
      })
      .then(({ data }) => data.createDeposit)
      .catch((e) => e);

    this.loading = false;

    if (deposit instanceof Error) {
      let errorMessage = 'Не удалось создать платеж. Попробуйте позже.';

      if(deposit.message.includes('You reached created deposit limit')){
        errorMessage = 'Вы превысили лимит на создание платежей, попробуйте позже'
      }

      this.$notify({
        type: "error",
        text: errorMessage,
      });
      return;
    }


    this.$emit("deposit-created", deposit);
  }
}
