import cardImg from "@/assets/deposits/card.png";
import qiwiImg from "@/assets/deposits/qiwi.png";
import cardRubImg from "@/assets/deposits/card-rub.png";
import bitcoinImg from "@/assets/deposits/bitcoin.png";
import ethereumImg from "@/assets/deposits/ethereum.png";
import usdtImg from "@/assets/deposits/usdt.png";
import privat24Img from "@/assets/deposits/privat24.png";
import steamImg from "@/assets/deposits/steam.png";
import webmoneyImg from "@/assets/deposits/webmoney.png";
import yooMoney from "@/assets/deposits/yoo-money.png";
import perfectMoney from "@/assets/deposits/perfect-money.svg";

export const PaymentSystemData = {
  APIKEY_PAY_CARD_RUB: {
    label: "Банковская карта #2 (RUB)",
    image: cardRubImg,
  },
  ALIKASSA_CARD_RUB: { label: "Банковская карта #2 (RUB)", image: cardRubImg },
  ALIKASSA_QIWI_RUB: { label: "QIWI кошелек #2 (RUB)", image: qiwiImg },
  ALIKASSA_YOOMONEY_RUB: {
    label: "ЮMoney #2 (RUB)",
    image: yooMoney,
  },
  OBMENKA_CARD_RUB: { label: "Банковская карта (RUB)", image: cardRubImg },

  SKY_CRYPTO_RUB: {
    label: "Банки РФ / ЮMoney / СБП",
    profitable: true,
    showMinGiveAmount: true,
    image: cardRubImg,
  },

  NICEPAY_RUB: {
    label: "Банки РФ / СБП",
    profitable: false,
    showMinGiveAmount: true,
    image: cardRubImg,
  },

  ENOT_CARD_RUB: { label: "Банковская карта (RUB)", image: cardRubImg },
  ENOT_QIWI_RUB: { label: "QIWI кошелек", image: qiwiImg },
  ENOT_PERFECT_MONEY_RUB: { label: "Perfect Money (RUB)", image: perfectMoney },
  ENOT_YOO_MONEY_RUB: { label: "ЮMoney", image: yooMoney },

  PAYPALYCH_CARD_USDT_RUB: {
    profitable: true,
    label: "Банковская карта / СБП",
    image: cardRubImg,
  },

  OBMENKA_BITCOIN_BTC: { label: "Bitcoin", image: bitcoinImg },
  OBMENKA_ETHEREUM_ETH: { label: "Ethereum", image: ethereumImg },
  OBMENKA_USDT_ERC20_USD: {
    label: "USDT (ERC20)",
    image: usdtImg,
  },
  OBMENKA_USDT_TRC20_USD: { label: "USDT (TRC20)", image: usdtImg },
  OBMENKA_USDT_ERC20_USDT: {
    label: "USDT (ERC20)",
    image: usdtImg,
  },
  OBMENKA_USDT_TRC20_USDT: { label: "USDT (TRC20)", image: usdtImg },

  CRYPTO_PAYMENTS_TRC20_USDT: {
    label: "USDT (TRC20)",
    image: usdtImg,
    profitable: true,
    showMinGiveAmount: true,
  },

  CRYPTO_PAYMENTS_ERC20_USDT: {
    label: "USDT (ERC20)",
    image: usdtImg,
    profitable: true,
    showMinGiveAmount: true,
  },

  SKINSPLUS_RUB: { label: "Пополнение скинами", image: steamImg },
  APIKEY_PAY_CARD_UAH: { label: "Банковская карта #2 (UAH)", image: cardImg },
  OBMENKA_CARD_UAH: { label: "Банковская карта (UAH)", image: cardImg },
  APIKEY_PAY_QIWI_RUB: { label: "QIWI кошелек", image: qiwiImg },
  OBMENKA_QIWI_RUB: { label: "QIWI кошелек", image: qiwiImg },
  APIKEY_PAY_PRIVAT24_UAH: { label: "Приват 24", image: privat24Img },
  OTHER_RUB: { label: "Пополнение деньгами", image: cardImg },
  ADMIN_RUB: { label: "Пополнение администрацией", image: cardImg },
  CHAT_GIVEAWAY_RUB: { label: "Викторина", image: cardImg },
  TELEGRAM_BONUS_RUB: { label: "Бонус за телеграм", image: cardImg },

  PAY_OP_WEBMONEY_USD: {
    label: "Webmoney (USD)",
    image: webmoneyImg,
    email: true,
  },
  PAY_OP_WEBMONEY_EUR: {
    label: "Webmoney (EUR)",
    image: webmoneyImg,
    email: true,
  },
  PAY_OP_CARD_RUB: {
    label: "Банковская карта #2 (RUB)",
    image: cardRubImg,
    email: true,
  },
  PAY_OP_CARD_USD: {
    label: "Банковская карта (USD)",
    image: cardImg,
    email: true,
  },
  PAY_OP_CARD_EUR: {
    label: "Банковская карта (EUR)",
    image: cardImg,
    email: true,
  },
};

export class DepositPaymentSystemEntity {
  _id!: string;
  image!: string;
  label!: string;
  email?: boolean;
  commission!: string;
  minGiveAmount!: number;

  constructor(opts: any) {
    Object.assign(this, opts);
    !PaymentSystemData[this._id] ||
      Object.assign(this, PaymentSystemData[this._id]);
  }
}
