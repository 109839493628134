import gql from "graphql-tag";

export const AuthUserFragment = gql`
  fragment AuthUser on User {
    _id
    role
    avatar
    balance
    snowBalance
    bonusBalance
    email
    level
    levelPoints
    createdAt
    chatBannedBefore
    depositsSum
    withdrawalsSum
    upgradesCount
    boxesCount
    userBoxesCount
    freeBoxesCount
    getTelegramBonus
    accountBoxesCount
    prevLevelPoints
    nextLevelPoints
    skipUpgradeConfirmation
    tradeUrl
    name
  }
`;

export const ChatMessageFragment = gql`
  fragment ChatMessage on ChatMessage {
    _id
    createdAt
    deleted
    trusted
    text
    user {
      _id
      avatar
      name
      role
      level
    }
    userId
  }
`;

export const ChatGiveawayFragment = gql`
  fragment ChatGiveaway on ChatGiveaway {
    _id
    question
    answer
    prize
    active
  }
`;

export const DepositFragment = gql`
  fragment Deposit on Deposit {
    _id
    getAmount
    paymentSystem
    state
    giveAmount
  }
`;

export const WithdrawalFragment = gql`
  fragment Withdrawal on Withdrawal {
    _id
    getAmount
    paymentSystem
    state
    giveAmount
  }
`;

export const AccountBoxFragment = gql`
  fragment AccountBox on AccountBox {
    _id
    image
    isAvailable
    name
    price
  }
`;

export const PartialUserFragment = gql`
  fragment PartialUser on PartialUserEntity {
    _id
    avatar
    balance
    snowBalance
    bonusBalance
    createdAt
    email
    level
    levelPoints
    prevLevelPoints
    nextLevelPoints
    depositsSum
    withdrawalsSum
    upgradesCount
    boxesCount
    freeBoxesCount
    userBoxesCount
    accountBoxesCount
    getTelegramBonus
    chatBannedBefore
    tradeUrl
    name
  }
`;

export const BoxFragment = gql`
  fragment Box on Box {
    _id
    image
    itemsPriceRange
    name
    isAvailable
    price
  }
`;

export const GunBoxFragment = gql`
  fragment GunBox on GunBox {
    _id
    image
    minItemPrice
    maxItemPrice
    type
    name
    price
    searchType
    userItemNames
  }
`;

export const SnowBoxFragment = gql`
  fragment SnowBox on SnowBox {
    _id
    image
    minItemPrice
    maxItemPrice
    name
    price
  }
`;

export const FreeBoxFragment = gql`
  fragment FreeBox on FreeBox {
    _id
    image
    name
    isAvailable
    levelPoints
    level
    itemIds
    frequencyInMilliseconds
  }
`;

export const BattleUserEntityFragment = gql`
  fragment BattleUserEntity on BattleUserEntity {
    _id
    avatar
    name
    level
  }
`;

export const BattleFragment = gql`
  fragment Battle on Battle {
    _id
    completed
    gunBoxIds
    price
    profitAmount
    userCount
    userIds
    winnerId
    profitUserItemIds
    users {
      ...BattleUserEntity
    }
  }
  ${BattleUserEntityFragment}
`;

export const UpgradeFragment = gql`
  fragment Upgrade on Upgrade {
    _id
    betAmount
    betItemId
    desiredItemId
    probability
    profitAmount
    profitItemId
  }
`;

export const UserItemWithoutUserFragment = gql`
  fragment UserItemWithoutUser on UserItem {
    _id
    image
    name
    price
    rarity
    state
    steamBotId
    fragmentId
    buyPrice
    userId
  }
`;

export const AccountFragment = gql`
  fragment Account on Account {
    _id
    image
    price
    description
    isForBox
    link
    userId
  }
`;

export const AccountShortFragment = gql`
  fragment AccountShort on Account {
    _id
    image
    price
    description
  }
`;

export const UserItemShortFragment = gql`
  fragment UserItemShort on UserItem {
    _id
    image
    price
    name
    rarity
  }
`;

export const UserItemWithUserFragment = gql`
  fragment UserItemWithUser on UserItem {
    _id
    image
    name
    price
    rarity
    state
    steamBotId
    userId
    fragmentId
    fromDocumentType
    fromDocument {
      _id
      name
      image
    }
    user {
      _id
      avatar
      name
    }
  }
`;

export const FragmentBoxesBalanceFragment = gql`
  fragment FragmentBoxesBalance on FragmentBoxesBalance {
    _id
    FRAGMENT_1
    FRAGMENT_2
    FRAGMENT_3
    FRAGMENT_4
  }
`;

export const FragmentBoxFragment = gql`
  fragment FragmentBox on FragmentBox {
    _id
    fragmentId
    maxItemPrice
    minItemPrice
    price
    name
  }
`;

export const UserItemWithFromDocumentFragment = gql`
  fragment UserItemWithFromDocument on UserItem {
    _id
    image
    name
    unExistsOnSkinsPlus
    price
    rarity
    state
    steamBotId
    userId
    fragmentId
    fromDocumentType
    fromDocument {
      _id
      name
      image
    }
  }
`;
